import React from "react"
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner/Banner";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Layout from "../../components/Layout/layout"
import ReferenceRequest from "../../Views/Website/Reference/Reference";

const ReferenceRequestPage: React.FC = () => {
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Reference Request</title><meta charSet='utf-8' />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "", Text: "Reference Request" }
      ]} />

      <ReferenceRequest />
    </Layout>
  );
};

export default ReferenceRequestPage;