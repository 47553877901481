import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { API_SaveReference } from "../../Services/ApiRoutes";
import { Fetch } from "../../Services/Fetch";
import * as styles from "./ReferenceForm.module.scss";
import { Reference } from "../../Classes/Employee";
import { Button } from "../../Views/Portal/Courses/Components/Button/Button";

type props = {
  Reference: Reference;
  setReference: (Reference: Reference) => void;
  isReview: boolean;
}

const ReferenceForm: React.FC<props> = ({ Reference, setReference, isReview }) => {
  const [captcha, setCaptcha] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const Submit = async (Event: any) => {
    Event.preventDefault();
    let refData: Reference = JSON.parse(JSON.stringify(Reference));
    refData.isComplete = true;
    refData.hasRead = false;
    refData.SentOn = new Date().toISOString();

    await Fetch(`${API_SaveReference}`, refData).then((Ref: Reference) => {
      if (Ref.ID)
        setReference(Ref);
    })
  }

  return (<>
    <form className={styles.Form} onSubmit={(e) => Submit(e)}>
      <div className={`${styles.FormInner}`}>
        <div className={`${styles.Input}`}>
          <label>What is your relationship to {Reference.Employee.Personal.Forename} {Reference.Employee.Personal.Surname}? *</label>
          {isReview ? <pre>{Reference.Relationship}</pre> :
            <input value={Reference.Relationship} type="text" placeholder="..." onChange={(e) => setReference({ ...Reference, Relationship: e.target.value })} required />}
        </div>

        {Reference.isCharacter ? <>
          <div className={`${styles.Input}`}>
            <label>How long have you known {Reference.Employee.Personal.Forename} {Reference.Employee.Personal.Surname}? *</label>
            {isReview ? <pre>{Reference.LengthOfKnowing}</pre> :
              <input value={Reference.LengthOfKnowing} type="text" placeholder="..." onChange={(e) => setReference({ ...Reference, LengthOfKnowing: e.target.value })} required />}
          </div>
          <div className={`${styles.Input} ${styles}`}>
            <label>How would you describe {Reference.Employee.Personal.Forename} {Reference.Employee.Personal.Surname}'s character?' *</label>
            {isReview ? <pre>{Reference.Character}</pre> :
              <textarea value={Reference.Character} placeholder="..." onChange={(e) => setReference({ ...Reference, Character: e.target.value })} required />}
          </div>
          <div className={`${styles.Checkbox}`}>
            <input checked={Reference.WouldReEmploy} disabled={isReview} type="checkbox" onChange={(e) => setReference({ ...Reference, WouldReEmploy: e.target.checked })} />
            <label>Would you recommend {Reference.Employee.Personal.Forename} {Reference.Employee.Personal.Surname} for this role? (Check for "yes")</label>
          </div>

          {Reference.WouldReEmploy ? <></> : <div className={`${styles.Input} ${styles}`}>
            <label>If no, please give a reason *</label>
            {isReview ? <pre>{Reference.NoReason}</pre> :
              <textarea disabled={Reference.WouldReEmploy} value={Reference.NoReason} placeholder="..." onChange={(e) => setReference({ ...Reference, NoReason: e.target.value })} required />}
          </div>}
        </> : <>
          <div className={`${styles.Input} ${styles.Half}`}>
            <label>Employment Start *</label>
            {isReview ? <pre>{Reference.EmploymentStart}</pre> :
              <input value={Reference.EmploymentStart} type="date" placeholder="..." onChange={(e) => setReference({ ...Reference, EmploymentStart: e.target.value })} required />}
          </div>

          <div className={`${styles.Input} ${styles.Half}`}>
            <label>Employment End *</label>
            {isReview ? <pre>{Reference.EmploymentEnd}</pre> :
              <input value={Reference.EmploymentEnd} type="date" placeholder="..." onChange={(e) => setReference({ ...Reference, EmploymentEnd: e.target.value })} required />}
          </div>

          <div className={`${styles.Input} ${styles}`}>
            <label>Nature of Job *</label>
            {isReview ? <pre>{Reference.Role}</pre> :
              <textarea value={Reference.Role} placeholder="..." onChange={(e) => setReference({ ...Reference, Role: e.target.value })} required />}
          </div>

          <div className={`${styles.Input} ${styles}`}>
            <label>How would you describe their performance in this role? *</label>
            {isReview ? <pre>{Reference.Performance}</pre> :
              <textarea value={Reference.Performance} placeholder="..." onChange={(e) => setReference({ ...Reference, Performance: e.target.value })} required />}
          </div>

          <div className={`${styles.Checkbox}`}>
            <input checked={Reference.WouldReEmploy} disabled={isReview} type="checkbox" onChange={(e) => setReference({ ...Reference, WouldReEmploy: e.target.checked })} />
            <label>Would you re-employ this person? (Check for "yes")</label>
          </div>

          {Reference.WouldReEmploy ? <></> : <div className={`${styles.Input} ${styles}`}>
            <label>If no, please give a reason *</label>
            {isReview ? <pre>{Reference.NoReason}</pre> :
              <textarea disabled={Reference.WouldReEmploy} value={Reference.NoReason} placeholder="..." onChange={(e) => setReference({ ...Reference, NoReason: e.target.value })} required />}
          </div>}

          <div className={`${styles.Input} ${styles}`}>
            <label>Why did they leave your company? *</label>
            {isReview ? <pre>{Reference.LeaveReason}</pre> :
              <textarea value={Reference.LeaveReason} placeholder="..." onChange={(e) => setReference({ ...Reference, LeaveReason: e.target.value })} required />}
          </div>
        </>}

        <div className={`${styles.Input} ${styles}`}>
          <label>Is there any additional information you think we should know? (Optional)</label>
          {isReview ? <pre>{Reference.AdditionalInformation}</pre> :
            <textarea value={Reference.AdditionalInformation} placeholder="..." onChange={(e) => setReference({ ...Reference, AdditionalInformation: e.target.value })} required />}
        </div>

        {isReview ? <></> : <>
          <div className="capcha">
            <ReCAPTCHA
              onExpired={() => { setMessage("RECAPTCHA has expired, please try again"); }}
              onErrored={() => { setMessage("An Error has Occurred on the RECAPTCHA, please try again"); }}
              sitekey="6Lf-XiQaAAAAAI5mHpzBUc6vtyvEWTS17PLcGnWT" onChange={e => { setCaptcha(e); setMessage("") }} />
          </div>

          {message ? <div className={styles.Message}>
            {message}
          </div> : <></>}

          <Button Type="submit" Disabled={captcha === ""}>
            Submit Reference
          </Button>
        </>}
      </div>
    </form>
  </>);
};

export default ReferenceForm;
