import React, { useEffect, useState } from 'react';
import * as styles from './Reference.module.scss';
import { Fetch } from '../../../Services/Fetch';
import { API_GetReference } from '../../../Services/ApiRoutes';
import { Reference } from '../../../Classes/Employee';
import { Loading } from '../../../components/Loading/Loading';
import ReferenceForm from '../../../components/ReferenceForm/ReferenceForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const ReferenceRequest: React.FC = () => {
  const [Reference, setReference] = useState<Reference>({} as Reference);

  useEffect(() => {
    GetReference();
  }, [])

  const GetReference = async () => {
    let id: string = location.search.split("ID=")[1];
    if (id)
      await Fetch(`${API_GetReference}`, id).then((Ref: Reference) => {
        setReference(Ref);
      });
  }

  return (<>
    <div className={styles.Body}>
      {Reference.ID ? Reference.isComplete ? <>
        <div className={styles.Complete}>
          <i>
            <FontAwesomeIcon icon={faCheckCircle} />
          </i>
          <h1>Reference Complete</h1>
          <p>Thank You For Completing This {Reference.isCharacter ? "Character " : ""}Reference For {Reference.Employee.Personal.Forename} {Reference.Employee.Personal.Surname}</p>
        </div>
      </> : <>
        <h1>{Reference.Employee.Personal.Forename} {Reference.Employee.Personal.Surname} {Reference.isCharacter ? "Character " : ""}Reference</h1>
        <p>Please write an honest {Reference.isCharacter ? "Character " : ""}Reference for {Reference.Employee.Personal.Forename} {Reference.Employee.Personal.Surname}. This information is confidential and stored securely, it will NOT be shared with {Reference.Employee.Personal.Forename} {Reference.Employee.Personal.Surname}.</p>
        <ReferenceForm Reference={Reference} setReference={(r) => setReference(r)} isReview={false} />
      </> : <div className={styles.Loading}>
        <Loading />
      </div>}
    </div>
  </>);
};

export default ReferenceRequest;
