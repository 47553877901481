// extracted by mini-css-extract-plugin
export var Body = "ReferenceForm-module--Body--90579";
export var Checkbox = "ReferenceForm-module--Checkbox--a0510";
export var Form = "ReferenceForm-module--Form--c25e3";
export var FormInner = "ReferenceForm-module--FormInner--9f7f8";
export var Green = "ReferenceForm-module--Green--30f94";
export var Half = "ReferenceForm-module--Half--4b237";
export var Input = "ReferenceForm-module--Input--09349";
export var Loading = "ReferenceForm-module--Loading--974b4";
export var Message = "ReferenceForm-module--Message--c8a40";
export var Modal = "ReferenceForm-module--Modal--1356a";
export var ModalBackground = "ReferenceForm-module--ModalBackground--e38b2";
export var ModalBody = "ReferenceForm-module--ModalBody--7ad7f";
export var ModalContent = "ReferenceForm-module--ModalContent--04fb9";
export var ModalFooter = "ReferenceForm-module--ModalFooter--979e2";
export var ModalHeader = "ReferenceForm-module--ModalHeader--c3b2b";
export var PasswordStength = "ReferenceForm-module--PasswordStength--b02ae";
export var PasswordStengthBar = "ReferenceForm-module--PasswordStengthBar--cff7f";
export var Show = "ReferenceForm-module--Show--599a7";
export var Third = "ReferenceForm-module--Third--1c938";